import React from 'react';
import { Info } from 'react-feather';
import Scrollspy from 'react-scrollspy';
import { Typography, Popover } from '@material-ui/core';
import { Code } from '../components/Code';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import Layout from '../layouts/LandingPage';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Branding } from '../components/Branding';
import { Section } from '../components/Section';
import { Hero, HeroInner, HeroTitle, HeroImage } from '../components/Hero';
import { ScrollSpyMenu } from '../components/ScrollSpyMenu';

type TermValue = { [name: string]: { label: string; description: string } };

const TERMS: TermValue = {
  sitemap: {
    label: 'Sitemap',
    description:
      "A sitemap is an XML file that contains all the URLs on your website that you want to be discoverable by search engines. Each URL should refer to the page's canonical URL, and may contain additional information like the frequency of updates and last modified date. Sitemaps can also be nested, meaning they contain links to other sitemaps."
  },
  gsc: {
    label: 'Google Search Console',
    description:
      'Google Search Console is a dedicated tool that Google provides webmasters like us to see how our site performs in organic search. You can see which pages get the most organic traffic, which keywords your site is receiving impressions and clicks for, and do things like test the mobile-friendliness of your website.'
  },
  serps: {
    label: 'SERPs',
    description:
      "SERPs is an acronym for Search Engine Results Page. This is the page that a search engine displays to a user after they've input their search query."
  }
};

const TermWrapper = styled('span')`
  font-weight: 700;
  color: #7b8694;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PopupWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(3)}px;
  max-width: 300px;
`;

const Term: React.FC<{ term: keyof TermValue }> = ({ term, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = `term-${term}`;
  const open = Boolean(anchorEl);

  return (
    <span style={{ display: 'inline-block', position: 'relative' }}>
      <TermWrapper aria-role="button" onClick={handleClick}>
        {children}&nbsp;
        <Info size={16} />
      </TermWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <PopupWrapper>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 700 }}
            paragraph
          >
            {TERMS[term].label}
          </Typography>
          <Typography variant="body1" component="p">
            {TERMS[term].description}
          </Typography>
        </PopupWrapper>
      </Popover>
    </span>
  );
};

const KEYED_SECTIONS = {
  technicalSeo: {
    title: 'Technical SEO',
    href: '#technical-seo',
    description:
      'Learn how to configure your website for SEO using modern technologies like React and Nginx.',
    src: '/images/seo-for-developers/launch.svg',
    color: '#899bfe',
    imgProps: { width: 300 }
  },
  keywordResearch: {
    title: 'Keyword Research',
    description:
      'Figure out what types of content people want to read and share using keyword research.',
    href: '#keyword-research',
    src: '/images/seo-for-developers/unicorn.svg',
    color: '#17c7b0',
    imgProps: { width: 280, right: 24, top: -45 }
  },
  contentSeo: {
    title: 'Content',
    description:
      'Optimize your content for search and sharing through proven on-page techniques.',
    href: '#content-seo',
    src: '/images/seo-for-developers/dark-mode.svg',
    color: '#ff4285',
    imgProps: { width: 280, right: 0, top: 0 }
  },
  linkBuilding: {
    title: 'Link Building',
    description:
      'Attract links to your content from quality websites that help you rank higher with these strategies.',
    src: '/images/seo-for-developers/links.svg',
    href: '#link-building',
    color: '#ffd278',
    imgProps: { width: 380 }
  },
  analytics: {
    title: 'UX and Analytics',
    description:
      'Use tools to measure and improve how your site performs from a user and search engine perspective.',
    src: '/images/seo-for-developers/moonlight.svg',
    href: '#analytics',
    color: '#48bcef',
    imgProps: { width: 380, top: 43, right: -12 }
  }
};

const SECTIONS = Object.values(KEYED_SECTIONS);

const SectionHeading = styled(Typography)`
  text-align: center;
  font-weight: 700;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const COLOR = '#69c0ff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    border-bottom: 2px solid ${COLOR};
    color: ${COLOR};
  `};
`;

const StyledA = styled('a')`
  border-bottom: 2px solid ${COLOR};
  color: ${COLOR};
`;

const Content = styled('div')`
  max-width: 800px;
  width: 80%;
  margin: ${(p) => p.theme.spacing(8)}px auto;
`;

const StyledParagraph = styled(Typography)`
  code {
    display: inline-block;
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #101010;
    border: 1px solid #444;
    color: #eee;
    padding: 6px 8px;
    border-radius: 8px;
    line-height: 1em;
    font-size: 0.9em;
  }
`;

const Paragraph: React.FC = ({ children }) => (
  <StyledParagraph variant="h6" component="p" paragraph>
    {children}
  </StyledParagraph>
);

const StyledSubheading = styled(Typography)`
  font-weight: 700;

  &:not(:first-child) {
    margin-top: ${(p) => p.theme.spacing(6)}px;
  }
`;

const SubHeading: React.FC<{ id: string; component?: 'h3' | 'h4' }> = ({
  children,
  id,
  component = 'h3'
}) =>
  component === 'h3' ? (
    <StyledSubheading variant="h4" component={component} id={id} gutterBottom>
      {children}
    </StyledSubheading>
  ) : (
    <StyledSubheading
      variant="h5"
      component={component}
      id={id}
      gutterBottom
      style={{ textTransform: 'uppercase', color: '#7b8694' }}
    >
      {children}
    </StyledSubheading>
  );

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="SEO for Developers"
        siteUrl="https://bloggingfordevs.com"
        description="The definitive guide to SEO for developers that explains how to make your website truly SEO-friendly from on-site to research and building your site's authority."
        pathname="seo-for-developers/"
        noIndex
      />
      <Header />
      <Hero>
        <HeroInner>
          <HeroTitle variant="h2" component="h1" gutterBottom>
            SEO for Developers
          </HeroTitle>
          <Typography variant="h6" component="p">
            The technically accurate guide to SEO fundamentals for developers
          </Typography>
          <Branding to="/" style={{ textAlign: 'left', marginTop: '24px' }}>
            By Blogging for Devs
          </Branding>
        </HeroInner>
        <HeroImage src="/images/seo-for-developers/dreamer.svg" />
      </Hero>
      <Content>
        <SectionHeading variant="h6" component="p">
          Table of Contents
        </SectionHeading>
        {SECTIONS.map((s) => (
          <Section key={s.title} {...s} />
        ))}
      </Content>
      <SectionHeading variant="h6" component="h2">
        Preface: Why Learn SEO as a Developer?
      </SectionHeading>
      <Content>
        <Paragraph>There's something that baffles me.</Paragraph>
        <Paragraph>
          Why are marketers the ones who ended up as experts at SEO instead of
          developers?
        </Paragraph>
        <Paragraph>
          When you think about it, we have some pretty unique advantages here:
        </Paragraph>
        <Typography variant="h6" component="ul" paragraph>
          <li>
            Deep understanding of how websites, algorithms, and the internet
            work
          </li>
          <li>
            Ability to design custom tools and automations to analyze or extract
            data
          </li>
          <li>Skills to create more interactive and unique content</li>
          <li>
            We can fix pretty much all our website's problems by ourselves!
          </li>
        </Typography>
        <Paragraph>
          Instead, most developers write off SEO as one of two things.
        </Paragraph>
        <Typography variant="h6" component="ol" paragraph>
          <li>
            A set of meta tags you apply once when you start developing a
            website
          </li>
          <li>
            Dark magic created by Google that cannot be reasoned about or
            understood
          </li>
        </Typography>
        <Paragraph>
          I hope that through this SEO guide, we can put both of those
          misconceptions to rest.
        </Paragraph>
        <Paragraph>
          Starting with a different mindset about how SEO works.
        </Paragraph>
        <Paragraph>
          You can think about SEO as the process of providing inputs, measuring
          outputs, and reverse-engineering the effect.
        </Paragraph>
        <Paragraph>
          <strong>
            In this beginner-friendly SEO guide, you will learn how to:
          </strong>
        </Paragraph>
        <Typography variant="h6" component="ol" paragraph>
          <li>Set up your website for correct, technical SEO end-to-end</li>
          <li>
            Audit an existing website for SEO issues with both free and paid
            tools
          </li>
          <li>Find valuable keywords using a variety of tools and methods</li>
          <li>
            Use keywords in your content so Google understands what it's about
          </li>
          <li>Measure whether your SEO improvements are having an effect</li>
        </Typography>
      </Content>
      <SectionHeading variant="h6" component="p">
        SEO for Developers
      </SectionHeading>
      <Hero id="technical-seo">
        <HeroInner>
          <HeroTitle
            variant="h2"
            component="h2"
            gutterBottom
            style={{
              color: KEYED_SECTIONS.technicalSeo.color
            }}
          >
            {KEYED_SECTIONS.technicalSeo.title}
          </HeroTitle>
          <Typography variant="h6" component="p">
            {KEYED_SECTIONS.technicalSeo.description}
          </Typography>
        </HeroInner>
        <HeroImage
          src={KEYED_SECTIONS.technicalSeo.src}
          style={{ right: 24, top: 12 }}
        />
      </Hero>
      <ScrollSpyMenu color={KEYED_SECTIONS.technicalSeo.color}>
        <Scrollspy
          items={[
            'what-is-technical-seo',
            'site-structure',
            'canonical-urls',
            'sitemap',
            'crawl-budget',
            'robots-txt',
            'redirects',
            'schema',
            'javascript',
            'internal-links',
            'thin-duplicate-content'
          ]}
          currentClassName="is-current"
          className="menu"
          offset={50}
        >
          <li>
            <a href="#what-is-technical-seo">What is Technical SEO?</a>
          </li>
          <li>
            <a href="#site-structure">Site structure</a>
          </li>
          <li>
            <a href="#canonical-urls">Canonical URLs</a>
          </li>
          <li>
            <a href="#sitemap">Sitemap</a>
          </li>
          <li>
            <a href="#crawl-budget">Crawl Budget</a>
          </li>
          <li>
            <a href="#robots-txt">Robots.txt</a>
          </li>
          <li>
            <a href="#redirects">Redirects</a>
          </li>
          <li>
            <a href="#schema">Schema and Metadata</a>
          </li>
          <li>
            <a href="#internal-links">Internal Links</a>
          </li>
          <li>
            <a href="#javascript">JavaScript</a>
          </li>
          <li>
            <a href="#pagespeed">PageSpeed</a>
          </li>
          <li>
            <a href="#accessibility">Accessibility</a>
          </li>
          <li>
            <a href="#thin-duplicate-content">Thin/Duplicate Content</a>
          </li>
        </Scrollspy>
        <Content>
          <SubHeading id="what-is-technical-seo">
            What is technical SEO?
          </SubHeading>
          <Paragraph>
            Technical SEO is the act of configuring your website so it can be
            optimially crawled, rendered, and indexed by search engines.
          </Paragraph>
          <Paragraph>
            When a crawler like Google's googlebot visits your website, it does
            a few things:
          </Paragraph>
          <Typography variant="h6" component="ol" paragraph>
            <li>Renders the web page and allows JavaScript to be executed</li>
            <li>
              Crawls the pages of your website by navigating internal links
            </li>
            <li>Indexes pages that appear to be valuable for searchers</li>
          </Typography>
          <Paragraph>
            Google's crawler can reach your website because you submitted your{' '}
            <Term term="sitemap">sitemap</Term> for indexing in{' '}
            <Term term="gsc">Google Search Console</Term>.
          </Paragraph>
          <Paragraph>
            But it can also find your website because another site on the
            internet linked to you. Google's crawler follows both internal and
            external links.
          </Paragraph>
          <Paragraph>
            The rest of this guide will explain the technical setup you need for
            your SEO-friendly website, as well as the technical explanation for{' '}
            <em>why</em> they're necessary.
          </Paragraph>
          <SubHeading id="site-structure">Site Structure</SubHeading>
          <Paragraph>
            When SEO specialists refer to site structure, there are two
            dimensions they are referring to:
          </Paragraph>
          <Typography variant="h6" component="ol" paragraph>
            <li>
              <strong>URL Structure</strong> &mdash; This is what you as a
              developer think about as the "path" of the URL. Most SEO guides
              refer to the parts of the path as "subfolders", even though we
              know that directory structure in the filesystem typically only
              mirrors to the path when serving static assets or using
              folder-based routing.
              <br />
              <br />
            </li>
            <li>
              <strong>Internal Link Structure</strong> &mdash; This refers to
              which web pages link to each other, usually contextually within
              the content itself. Sometimes, this internal structure is also
              represented in the path in the form of a subpath (for example,{' '}
              <code>/products/:slug</code>).
            </li>
          </Typography>
          <SubHeading id="url-structure" component="h4">
            How to structure your URLs
          </SubHeading>
          <Paragraph>
            In most cases, the place where you set up your URLs will be in your
            Router.
          </Paragraph>
          <Paragraph>
            <strong>Make sure to use kebab-case for your URLs.</strong>
          </Paragraph>
          <Paragraph>
            Although Google says that it understands snake_case URLs, you can
            find evidence in the search results that it doesn't quite work, at
            least when it renders breadcrumbs.
          </Paragraph>
          <Paragraph>
            Plus, the path (also called the "slug") of your page gives Google
            context about what the page is about.
          </Paragraph>
          <Paragraph>
            Most of the time, you'll probably end up using a keyword as the slug
            of your URL:
          </Paragraph>
          <Paragraph>
            <Code>https://yourwebsite.com/your-main-keyword</Code>
          </Paragraph>
          <Paragraph>
            This is especially true for blogs or small websites.
          </Paragraph>
          <Paragraph>
            If you're working on a larger website, for example an ecommerce
            website or a website with a lot of closely related pages, you may
            wish to organize them under categories through the URL.
          </Paragraph>
          <Paragraph>
            <Code>/your-main-keyword</Code>
          </Paragraph>
          <Paragraph>
            For whatever reason, Google has shown time and again that they hate
            URLs. In the search results, Google often displays the URL in the
            format of breadcrumbs, like this:
          </Paragraph>
          <Paragraph>This means, you can communicate</Paragraph>
          <SubHeading id="canonical-urls">Canonical URLs</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="canonical-url-important" component="h4">
            Why is your canonical URL so important?
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="canonical-url-important" component="h4">
            Anatomy of a canonical URL
          </SubHeading>
          <SubHeading id="canonical-url-important" component="h4">
            Common canonical URL mistakes
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="sitemap">Sitemap</SubHeading>
          <SubHeading id="crawl-budget">Crawl Budget</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="how-does-google-use-sitemaps" component="h4">
            How does Google use your sitemap?
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="sitemap-gatsby-nextjs" component="h4">
            Add a Sitemap to Gatsby or Next.js
          </SubHeading>
          <SubHeading id="sitemap-hugo" component="h4">
            Add a Sitemap to Hugo
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="robots-txt">Robots.txt</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="redirects">Redirects</SubHeading>
          <Paragraph>
            <code>301</code> redirects
          </Paragraph>
          <SubHeading id="301-redirects" component="h4">
            Page URL changes
          </SubHeading>
          <SubHeading id="301-redirects" component="h4">
            HTTP to HTTPS
          </SubHeading>
          <SubHeading id="301-redirects" component="h4">
            WWW Consistency
          </SubHeading>
          <SubHeading id="301-redirects" component="h4">
            Trailing slashes
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="schema">Schema and Metadata</SubHeading>
          <Paragraph>Hreflang</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="published-and-last-modified" component="h4">
            Published and Last Modified dates
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="internal-links">Internal Links</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="javascript">JavaScript</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="published-and-last-modified" component="h4">
            How Google uses your internal links
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="nofollow-vs-dofollow-links" component="h4">
            Nofollow vs. Do follow links
          </SubHeading>
          <SubHeading id="published-and-last-modified" component="h4">
            Internal link maintenance methods
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="pagespeed">
            Why optimizing your PageSpeed has diminishing returns for SEO
          </SubHeading>
          <SubHeading id="accessibility">
            How Accessibility and SEO work together for better UX
          </SubHeading>
          <SubHeading id="thin-duplicate-content">
            Thin/Duplicate Content
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
        </Content>
      </ScrollSpyMenu>
      <Hero id="keyword-research">
        <HeroInner>
          <HeroTitle
            variant="h2"
            component="h2"
            gutterBottom
            style={{
              color: KEYED_SECTIONS.keywordResearch.color
            }}
          >
            {KEYED_SECTIONS.keywordResearch.title}
          </HeroTitle>
          <Typography variant="h6" component="p">
            {KEYED_SECTIONS.keywordResearch.description}
          </Typography>
        </HeroInner>
        <HeroImage
          src={KEYED_SECTIONS.keywordResearch.src}
          style={{ width: '35%', top: -36 }}
        />
      </Hero>
      <ScrollSpyMenu color={KEYED_SECTIONS.keywordResearch.color}>
        <Scrollspy
          items={[
            'what-is-keyword-research',
            'head-vs-tail-keywords',
            'search-volume',
            'search-intent',
            'keyword-research-tools',
            'choose-keywords',
            'lsi-keywords'
          ]}
          currentClassName="is-current"
          className="menu"
          offset={50}
        >
          <li>
            <a href="#what-is-keyword-research">WTF is a keyword?</a>
          </li>
          <li>
            <a href="#head-vs-tail-keywords">Head and Tail Keywords</a>
          </li>
          <li>
            <a href="#search-volume">Search Volume</a>
          </li>
          <li>
            <a href="#search-intent">Search Intent</a>
          </li>
          <li>
            <a href="#keyword-research-tools">Keyword Research Tools</a>
          </li>
          <li>
            <a href="#choose-keywords">Choosing Your Keywords</a>
          </li>
          <li>
            <a href="#lsi-keywords">LSI Keywords</a>
          </li>
        </Scrollspy>
        <Content>
          <SubHeading id="what-is-keyword-research">
            WTF is a keyword?
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="head-vs-tail-keywords">
            Head and Tail Keywords
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="double-dip-keywords" component="h4">
            How to "double dip" on keywords
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="search-volume">Search Volume</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="search-intent">
            This biggest mistake both beginners and pros make when targeting
            keywords
          </SubHeading>
          <SubHeading id="content-type-ranking" component="h4">
            Tip 1: Determine the ranking content type
          </SubHeading>
          <SubHeading id="content-length-ranking" component="h4">
            Tip 2: Check the length of ranking content
          </SubHeading>
          <SubHeading id="content-topics-ranking" component="h4">
            Tip 3: Check the topics covered by ranking content
          </SubHeading>
          <SubHeading id="keyword-research-tools">
            Introduction to keyword research tools
          </SubHeading>
          <SubHeading id="how-does-keyword-research-work" component="h4">
            How does keyword research work?
          </SubHeading>
          <SubHeading
            id="where-do-keyword-research-tools-get-their-data"
            component="h4"
          >
            Where do keyword research tools get their data?
          </SubHeading>
          <SubHeading id="popular-keyword-research-tools" component="h4">
            Popular Keyword Research Tools
          </SubHeading>
          <SubHeading id="choose-keywords">
            How to choose keywords you can actually rank for
          </SubHeading>
          <SubHeading id="keyword-difficulty" component="h4">
            How to assess keyword difficulty
          </SubHeading>
          <SubHeading id="domain-rating" component="h4">
            Domain Rating and Domain Authority (DR/DA)
          </SubHeading>
          <SubHeading id="websites-already-ranking" component="h4">
            Assess the type of websites already ranking
          </SubHeading>
          <SubHeading id="top-ranking-pages" component="h4">
            Check the backlinks for top ranking pages
          </SubHeading>
          <SubHeading id="top-ranking-pages" component="h4">
            Does Google ignore sites targeting these keywords already
          </SubHeading>
          <SubHeading id="lsi-keywords">LSI Keywords</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
          <Paragraph>Lorem ipsum</Paragraph>
        </Content>
      </ScrollSpyMenu>
      <Hero id="content-seo">
        <HeroInner>
          <HeroTitle
            variant="h2"
            component="h2"
            gutterBottom
            style={{
              color: KEYED_SECTIONS.contentSeo.color
            }}
          >
            {KEYED_SECTIONS.contentSeo.title}
          </HeroTitle>
          <Typography variant="h6" component="p">
            {KEYED_SECTIONS.contentSeo.description}
          </Typography>
        </HeroInner>
        <HeroImage
          src={KEYED_SECTIONS.contentSeo.src}
          style={{ right: 0, top: 0 }}
        />
      </Hero>
      <ScrollSpyMenu color={KEYED_SECTIONS.contentSeo.color}>
        <Scrollspy
          items={[
            'content-seo',
            'niching-down',
            'cornerstone-content',
            'title-and-description',
            'ctr',
            'url-structure',
            'headings',
            'internal-and-external-links',
            'time-on-page'
          ]}
          currentClassName="is-current"
          className="menu"
          offset={50}
        >
          <li>
            <a href="#content-seo">How does content impact SEO?</a>
          </li>
          <li>
            <a href="#niching-down">Niching down</a>
          </li>
          <li>
            <a href="#cornerstone-content">Cornerstone content</a>
          </li>
          <li>
            <a href="#title-and-description">Title and Description</a>
          </li>
          <li>
            <a href="#ctr">Click-through rate (CTR)</a>
          </li>
          <li>
            <a href="#url-structure">URL structure</a>
          </li>
          <li>
            <a href="#headings">H1, H2, H3s</a>
          </li>
          <li>
            <a href="#images">Images</a>
          </li>
          <li>
            <a href="#internal-and-external-links">
              Internal and External Links
            </a>
          </li>
          <li>
            <a href="#time-on-page">Time on Page</a>
          </li>
        </Scrollspy>
        <Content>
          <SubHeading id="content-seo">How does content impact SEO?</SubHeading>
          <SubHeading id="niching-down">Niching down</SubHeading>
          <SubHeading id="cornerstone-content">Cornerstone content</SubHeading>
          <SubHeading id="title-and-description">
            Title and Description
          </SubHeading>
          <SubHeading id="ctr">Click-through Rate (CTR)</SubHeading>
          <SubHeading id="url-structure">URL structure</SubHeading>
          <SubHeading id="headings">H1s, H2s, and H3s</SubHeading>
          <SubHeading id="images">Images</SubHeading>
          <SubHeading id="internal-and-external-links">
            Internal and External Links
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="time-on-page">Time on Page</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
        </Content>
      </ScrollSpyMenu>
      <Hero id="link-building">
        <HeroInner>
          <HeroTitle
            variant="h2"
            component="h2"
            gutterBottom
            style={{
              color: KEYED_SECTIONS.linkBuilding.color
            }}
          >
            {KEYED_SECTIONS.linkBuilding.title}
          </HeroTitle>
          <Typography variant="h6" component="p">
            {KEYED_SECTIONS.linkBuilding.description}
          </Typography>
        </HeroInner>
        <HeroImage src={KEYED_SECTIONS.linkBuilding.src} />
      </Hero>
      <ScrollSpyMenu color={KEYED_SECTIONS.linkBuilding.color}>
        <Scrollspy
          items={[
            'what-is-link-building',
            'what-are-backlinks',
            'backlink-value',
            'link-building-strategies',
            'link-worthy-content'
          ]}
          currentClassName="is-current"
          className="menu"
          offset={50}
        >
          <li>
            <a href="#what-are-backlinks">What are backlinks?</a>
          </li>
          <li>
            <a href="#what-is-link-building">What is link building?</a>
          </li>
          <li>
            <a href="#backlink-value">Determine the Value of a Backlink</a>
          </li>
          <li>
            <a href="#link-building-strategies">Link Building Strategies</a>
          </li>
          <li>
            <a href="#link-worthy-content">Link-worthy Content</a>
          </li>
        </Scrollspy>
        <Content>
          <SubHeading id="what-are-backlinks">What are backlinks?</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="what-is-link-building">
            What is link building?
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="backlink-value">
            Determine the Value of a Backlink
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="link-building-strategies">
            Link Building Strategies
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="link-worthy-content">Link-worthy Content</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
        </Content>
      </ScrollSpyMenu>
      <Hero id="analytics">
        <HeroInner>
          <HeroTitle
            variant="h2"
            component="h2"
            gutterBottom
            style={{
              color: KEYED_SECTIONS.analytics.color
            }}
          >
            {KEYED_SECTIONS.analytics.title}
          </HeroTitle>
          <Typography variant="h6" component="p">
            {KEYED_SECTIONS.analytics.description}
          </Typography>
        </HeroInner>
        <HeroImage
          src={KEYED_SECTIONS.analytics.src}
          style={{ bottom: 0, top: 'auto', right: 66 }}
        />
      </Hero>
      <ScrollSpyMenu color={KEYED_SECTIONS.analytics.color}>
        <Scrollspy
          items={['ux-seo']}
          currentClassName="is-current"
          className="menu"
          offset={50}
        >
          <li>
            <a href="#ux-seo">How does UX affect SEO?</a>
          </li>
          <li>
            <a href="#analytics">Google Analytics (and Alternatives)</a>
          </li>
          <li>
            <a href="#google-search-console">Google Search Console</a>
          </li>
          <li>
            <a href="#rank-tracking">Rank Tracking Tools</a>
          </li>
        </Scrollspy>
        <Content>
          <SubHeading id="ux-seo">How does UX affect SEO?</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="analytics">
            Google Analytics (And Alternatives)
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="google-search-console">
            Google Search Console
          </SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
          <SubHeading id="rank-tracking">Rank Tracking Tools</SubHeading>
          <Paragraph>Lorem ipsum</Paragraph>
        </Content>
      </ScrollSpyMenu>
      <SectionHeading variant="h6" component="h2">
        Epilogue: What should you do next?
      </SectionHeading>
      <SectionHeading variant="h6" component="h2">
        Glossary
      </SectionHeading>
      <Content>
        {Object.values(TERMS).map((term) => (
          <Paragraph>
            <strong>{term.label}</strong> &mdash; {term.description}
          </Paragraph>
        ))}
      </Content>
      <SectionHeading variant="h6" component="h2">
        References
      </SectionHeading>
      <SectionHeading variant="h6" component="h2">
        Topics Omitted
      </SectionHeading>
      <Content>
        <Typography variant="h6" component="p" style={{ display: 'none' }}>
          Expired domains, Private Blog Networks, Featured Snippets, AMP,
          Competitor Analysis, Content Gap Analysis, Brand Signals, Social
          Signals, EAT
        </Typography>
      </Content>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
